<template>
	<div class="SuggestList">
		<w-navTab titleText="意见建议"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="header">
				<div class="header-nav-list">
					<div :class="['nav-list-item', {'nav-active' : navIndex == index}]" v-for="(item,index) in navList"
						@click="onClickNav(index)">
						{{item.title}}({{index == 0 ? total.no_reply : total.replied}})
					</div>
				</div>
			</div>
			<div class="body">
				<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty"
					@onLoad="onLoad">
					<div class="body-list-item" v-for="(item,index) in suggestList">
						<div class="body-item-top">
							<div class="body-item-left">
								<img :src="item.avatar" alt="" />
							</div>
							<div class="body-item-right">
								<div class="item-right-name">
									<div class="item-name-text">
										{{item.name}}<span>{{item.create_time}}</span>
									</div>
									<div class="item-name-button"
										@click="$router.push({ name: 'SuggestReply', params:{id: item.id}})"
										v-if="navIndex == 0">回复</div>
								</div>
								<div class="item-right-text">
									{{item.level_text}} {{item.district_name}} {{item.title}}
								</div>
							</div>
						</div>
						<div class="body-item-text">
							{{item.content}}
						</div>
						<div class="body-item-bottom" v-if="navIndex == 1">
							<div class="body-bottom-label">
								<div class="body-bottom-label-icon" v-if="item.reply_avatar">
									<img :src="item.reply_avatar" alt="" />
								</div>
								<div class="body-bottom-label-text">
									{{item.reply_name}} 回复内容：
								</div>
							</div>
							<div class="body-bottom-text">{{item.reply_content}}</div>
							<div class="body-bottom-time">{{item.reply_time}}</div>
						</div>
					</div>
				</VantList>
			</div>
		</div>
	</div>
</template>

<script>
	import CompanyApi from '@/api/company';
	import VantList from '@/components/VantList';
	import VantVendor from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';

	export default {
		name: 'SuggestList',
		data() {
			return {
				loadingPage: true,
				navIndex: 0,
				navList: [{
					id: 2,
					title: '待处理'
				}, {
					id: 1,
					title: '已处理'
				}],
				loading: true,
				finished: false,
				isEmpty: false,
				paramsPage: 1,
				paramsLimit: 10,
				suggestList: [],
				total: ''
			}
		},
		created() {
			this.onLoad()
			this.getSuggestTotal()
		},
		methods: {
			getSuggestTotal() {
				CompanyApi.suggestTotal()
					.then(result => {
						this.total = result.data
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			onClickNav(index) {
				this.navIndex = index
				this.paramsPage = 1
				this.suggestList = []
				this.isEmpty = false
				this.finished = false
				this.onLoad()
			},
			onLoad() {
				this.loading = true;

				let params = {
					status: this.navList[this.navIndex].id,
					page: this.paramsPage,
					limit: this.paramsLimit
				}

				CompanyApi.suggestList(params)
					.then(result => {
						this.paramsPage++;
						this.suggestList = this.suggestList.concat(result.data);
						this.loadingPage = false
						this.loading = false;

						if (this.suggestList.length <= 0) {
							this.isEmpty = true;
						}

						if (result.data.length < this.paramsLimit) {
							this.finished = true;
						}
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
		},
		components: {
			VantList,
			VantVendor,
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.SuggestList {
		.container {
			.header {
				padding-bottom: 1px;
				background: #FFF;
				box-shadow: 0px -0.5px 0px 0px #E5E6EB inset;

				.header-nav-list {
					display: flex;
					justify-content: center;
					align-items: center;

					.nav-list-item {
						padding-top: 11px;
						color: #999;
						font-size: 15px;
						font-weight: bold;
						box-sizing: border-box;
						margin-right: 60px;

						&.nav-list-item:last-child {
							margin-right: 0;
						}

						&::after {
							content: '';
							display: block;
							width: 45px;
							height: 2px;
							background: #fff;
							margin: 8px auto 0;
						}

						&.nav-active {
							color: #333;

							&::after {
								content: '';
								display: block;
								margin-top: 8px;
								width: 45px;
								height: 2px;
								background: #37F;
								margin: 8px auto 0;
							}
						}
					}
				}
			}

			.body {
				padding: 16px;
				box-sizing: border-box;

				.body-list-item {
					padding: 12px 10px 16px;
					border-radius: 8px;
					background: #FFF;
					margin-bottom: 12px;

					&.body-list-item:last-child {
						margin-bottom: 0;
					}

					.body-item-top {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.body-item-left {
							width: 36px;
							height: 36px;
							min-width: 36px;
							margin-right: 8px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								border-radius: 50%;
							}
						}

						.body-item-right {
							flex: 1;

							.item-right-name {
								display: flex;
								justify-content: space-between;
								align-items: center;

								.item-name-text {
									color: #333;
									font-size: 16px;
									line-height: 16px;

									span {
										color: #999;
										font-size: 12px;
										line-height: 16px;
										margin-left: 4px;
									}
								}

								.item-name-button {
									color: #FF6969;
									font-size: 14px;
									font-weight: bold;
									line-height: 16px;
								}
							}

							.item-right-text {
								color: #333;
								font-size: 12px;
								line-height: 16px;
								margin-top: 4px;
							}
						}
					}

					.body-item-text {
						margin-top: 12px;
						color: #333;
						font-size: 14px;
						line-height: 21px;
					}

					.body-item-bottom {
						margin-top: 8px;
						padding-top: 8px;
						border-top: 1px solid #F4F4F4;
						box-sizing: border-box;

						.body-bottom-label {
							display: flex;
							align-items: center;
							
							.body-bottom-label-icon{
								width: 24px;
								height: 24px;
								min-width: 24px;
								margin-right: 2px;
								
								img{
									width: 100%;
									height: 100%;
									border-radius: 50%;
									object-fit: cover;
								}
							}
							.body-bottom-label-text{
								color: #999;
								font-size: 14px;
								line-height: 21px;
							}
						}

						.body-bottom-text {
							color: #FF6969;
							font-size: 14px;
							line-height: 21px;
							margin-top: 8px;
						}

						.body-bottom-time {
							margin-top: 8px;
							font-size: 12px;
							line-height: 16px;
							color: rgba(0, 0, 0, .5);
						}
					}
				}
			}
		}
	}
</style>